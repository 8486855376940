<template>
  <div class="home">
    <div class="banner-box">
      <div class="banner">
        <img v-if="teamData.banners" :src="teamData.banners[0].bgUrl" />
        <span>{{$store.state.lang === 'zh' ? teamData.banners[0].titleLangZh : teamData.banners[0].title}}</span>
      </div>
    </div>
    <p class="title title2 main-fadeInLeft wow" style="font-weight: no" v-if="teams.length > 0"> {{$t('investmentTeam')}}</p>
    <div class="grid-box container">
      <div class="grid-item" v-for="item in [...teams,...members]" :key="item.name">
        <div class="grid-center" @click="toDetails(item._id)">
          <img :class="
                  ['hover-rubberBand','hover-jello', 'hover-shakeX','hover-pulse']
                  [Math.floor((Math.random()*['hover-rubberBand','hover-jello','hover-shakeX','hover-pulse'].length))]
                " 
                :src="item.imgUrl" />
          <span class="name">{{ $store.state.lang=='zh'?item.nameLangZh: item.name}}</span>
          <span class="office">{{ $store.state.lang=='zh'?item.officeLangZh: item.office }}</span>
        </div>
      </div>
    </div>
    
    <!-- <div class="grid-box">
      <div class="grid-item" v-for="item in members" :key="item.name">
        <div class="grid-center" @click="toDetails(item._id)">
          <img class="hover-pulse team-avator" :src="item.imgUrl" />
          <span class="name">{{ $store.state.lang=='zh'?item.nameLangZh: item.name }}</span>
          <span class="office">{{  $store.state.lang=='zh'?item.officeLangZh: item.office }}</span>
        </div>
      </div>
    </div> -->
    
    <p class="title title2 main-fadeInLeft wow" v-if="advisors.length > 0">{{$t('strategicAdvisors')}}</p>
    <div class="grid-box container">
      <div class="grid-item" v-for="item in advisors" :key="item.name">
        <div class="grid-center" @click="toDetails(item._id)">
          <img 
            :class="
                ['hover-rubberBand','hover-jello', 'hover-shakeX','hover-pulse']
                [Math.floor((Math.random()*['hover-rubberBand','hover-jello','hover-shakeX','hover-pulse'].length))]
              " 
           :src="item.imgUrl" />
          <span class="name">{{ $store.state.lang=='zh'?item.nameLangZh: item.name }}</span>
          <span class="office">{{ $store.state.lang=='zh'?item.officeLangZh: item.office }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getTeamsContent} from "@/apis"
import {WOW} from "wowjs"
export default {
  name: "Portfolio",
  components: {},
  data() {
    return {
      bannerUri: require("@/assets/images/WechatIMG172.jpg"),
      teams: [
        // {
        //   name: "Harry Hui",
        //   office: "Founding Managing Partner",
        //   imgUrl: require("@/assets/images/t-p-1.jpg"),
        // },
        // {
        //   name: "William Chen",
        //   office: "Founding Managing Partner",
        //   imgUrl: require("@/assets/images/t-p-2.jpg"),
        // },
        // {
        //   name: "Kathleen Ying",
        //   office: "Partner",
        //   imgUrl: require("@/assets/images/t-p-3.jpg"),
        // },
        // {
        //   name: "Quan Li",
        //   office: "Partner",
        //   imgUrl: require("@/assets/images/t-p-4.jpg"),
        // },
      ],
      members: [
        // {
        //   name: "Harry Hui",
        //   office: "Founding Managing ",
        //   imgUrl: require("@/assets/images/t-p-5.jpg"),
        // },
        // {
        //   name: "William",
        //   office: "Founding Managing ",
        //   imgUrl: require("@/assets/images/t-p-6.jpg"),
        // },
        // {
        //   name: "Kathleen",
        //   office: "Partner",
        //   imgUrl: require("@/assets/images/t-p-7.jpg"),
        // },
        // {
        //   name: "Quan Li",
        //   office: "Partner",
        //   imgUrl: require("@/assets/images/t-p-8.jpg"),
        // },
        // {
        //   name: "Quan Li1",
        //   office: "Partner",
        //   imgUrl: require("@/assets/images/t-p-9.jpg"),
        // },
      ],

      advisors: [
        // {
        //   name: "Silas Chou",
        //   office: "President & CEO Novel Enterprises",
        //   imgUrl: require("@/assets/images/t-p-10.jpg"),
        // },
        // {
        //   name: "Junling Liu1",
        //   office: "Co-founder & Chairman 111,Inc",
        //   imgUrl: require("@/assets/images/t-p-11.jpg"),
        // },
        // {
        //   name: "Jackson Tai",
        //   office: "Former CEO & Vice Chairman DBS Bank",
        //   imgUrl: require("@/assets/images/t-p-12.jpg"),
        // },
        // {
        //   name: "Roland Berger",
        //   office: "Founder Roland Berger GmbH",
        //   imgUrl: require("@/assets/images/t-p-13.jpg"),
        // },
        // {
        //   name: "Ram Krishnan",
        //   office: "President & CEO Greater China Region PepsiCo",
        //   imgUrl: require("@/assets/images/t-p-14.jpg"),
        // },
        // {
        //   name: "Seng Huang Lee",
        //   office: "Group Executive Chairman Sun Hung Kai & Co Ltd",
        //   imgUrl: require("@/assets/images/t-p-15.jpg"),
        // },
        // {
        //   name: "Bill Roedy",
        //   office: "Former Chairman & CEO MTV Networks International",
        //   imgUrl: require("@/assets/images/t-p-16.jpg"),
        // },
        // {
        //   name: "Fred Langhammer",
        //   office: "Chairman, Global Affairs Estée Lauder",
        //   imgUrl: require("@/assets/images/t-p-7.jpg"),
        // },
      ],
    };
  },
  computed:{
    teamData(){
      const team = this.$store.state.mainContent.team
      const lang = this.$store.state.lang
      let obj = {}
      if(team && team.extend){
       
        obj = {
          ...team,
          ...JSON.parse(team.extend)
        }
         console.log(obj);

      }
      return obj
    }
  },
  methods:{
    toDetails(id){
      this.$router.push('/member/'+id)
    },
    async getTeamsContent(){
      
      const res = await getTeamsContent()
      res.data.sort( (a, b) => {
        return b.sort - a.sort
      }).map( (item, index ) => {
        
        if(item.typeid == 1){
          if(index < 4){
            this.teams.push({
              ...item,
              office: item.post,
              officeLangZh: item.postLangZh,
            },)
          } else {
            this.members.push({
              ...item,
              office: item.post,
              officeLangZh: item.postLangZh,
            },)
          }
          
          
        } else if(item.typeid == 2){
          this.advisors.push({
            ...item,
            office: item.post,
            officeLangZh: item.postLangZh,
          },)
        }



      })
    }
  },
  mounted(){
    // document.title = this.$store.state.lang=='en'?'ClearVue': '团 队'
    
    
    this.getTeamsContent()

    this.$nextTick(()=>{
      new WOW().init()
    })
  }
};
</script>

<style lang="scss" scoped>
.home {
  letter-spacing: 0px;
  font-weight: normal;
  font-stretch: normal;
}

.banner-box {
  font-family: Arial;
  padding-top: 106px;
  .banner {
    position: relative;
    img {
      // height: 330px;
      width: 100%;
    }
    span {
      display: inline-block;
      position: absolute;
      color: #fff;
      font-size: 50px;
      z-index: 1;
      top: 50%;
      // transform: translate(-50%, -50%);
      right: 50px;
    }
  }
}

.title {
  font-family: Arial;
  font-size: 30px;
  font-weight: bold;
  line-height: 148px;
  text-align: center;
  width: 100%;
}
.title2 {
  font-weight: normal;
}

.grid-box {
  display: flex;
  flex-wrap: wrap;
  padding: 0 145px;
  max-width: 1200px;
  margin: 0 auto;
  .grid-item {
    flex-basis: 25%;
    box-sizing: border-box;
    cursor: pointer;
    padding: 3px;
    height: auto;
    margin-bottom: 56px;
  }

  .grid-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .office {
    font-family: Arial;
    font-size: 12px;
    height: 35px;
  }

  .member {
    flex-basis: 20%;
    .office {
      height: 48px;
    }
  }

  .name {
    font-family: Arial;
    font-size: 18px;
    line-height: 50px;
    font-weight: bold;
  }
}
</style>
